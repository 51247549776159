import { MouseEvent, useCallback, useMemo, useState } from "react";
import { MRT_ColumnDef } from "material-react-table";
import { Chip, MenuItem, Stack } from "@mui/material";
import { ActionCell } from "../../components/ActionCell/ActionCell";
import { OneLiner } from "../../components/Table/Table";
import { userFriendlyDateTimestamp } from "../../utils/timestamps";
import { useHasPermission } from "../../hooks";
import { UserPermissions } from "../../utils/permissions";
import { TabKeyEnum } from "../../utils/types";

export type ActionType = "Publish" | "Edit" | "UnPublish" | "Remove" | "EditTranslation" | "View" | "Archive" | "Unarchive";

interface Params {
  activeTab: TabKeyEnum;
  setHoveredRow: (rowIndex: number) => void;
  hoveredRow: number;
  handleTableAction: (action: ActionType, item: any) => void;
}

interface MenuItemInterface {
  title: string;
  action: ActionType;
  visible: boolean;
  color?: string;
}

const defaultColumns: MRT_ColumnDef<any>[] = [
  {
    accessorKey: "engText",
    header: "Quote",
    size: 500,
    filterVariant: "autocomplete",
    enableColumnFilter: true,
    Cell: ({ row }) => <OneLiner text={row.original.engText} />,
  },
  {
    accessorKey: "origLang",
    header: "Origin Language",
    size: 200,
    enableColumnFilter: true,
    Cell: ({ row }) => <OneLiner text={row.original.origLang} />,
  },
  {
    accessorKey: "origText",
    header: "Origin Language Text",
    size: 300,
    Cell: ({ row }) => <OneLiner text={row.original.origText} />,
    enableColumnFilter: false,
  },
  {
    accessorKey: "originCulture",
    header: "Origin Culture",
    size: 200,
    Cell: ({ row }) => <OneLiner text={row.original.originCulture} />,
    enableColumnFilter: true,
  },
  {
    accessorKey: "source",
    header: "Source",
    Cell: ({ row }) => <OneLiner text={row.original.source} />,
    enableColumnFilter: true,
  },
  {
    accessorKey: "tags",
    header: "Tags",
    size: 300,
    enableSorting: false,
    Cell: ({ row }) => (
      <Stack direction="row" spacing={1}>
        {row.original.tags?.map(({ tag, id }: any) => {
          return <Chip key={id} label={tag} />;
        })}
      </Stack>
    ),
    accessorFn: originalRow => originalRow.tags?.map(({ tag }: any) => tag).join(", "),
  },
  {
    accessorKey: "author",
    header: "Author",
    Cell: ({ row }) => <OneLiner text={row.original.author} />,
    enableColumnFilter: true,
  },
  {
    accessorKey: "reference",
    header: "Reference",
    size: 300,
    Cell: ({ row }) => <OneLiner text={row.original.reference} />,
  },
  {
    accessorKey: "createdBy",
    header: "Created By",
    enableSorting: false,
    enableColumnFilter: true,
    Cell: ({ row }) => <OneLiner text={row.original.createdBy?.name} />,
    accessorFn: originalRow => originalRow.createdBy?.name,
  },
  {
    accessorKey: "origTimePeriod",
    header: "Origin Time Period",
    Cell: ({ row }) => <OneLiner text={row.original.origTimePeriod} />,
    enableColumnFilter: true,
  },
  {
    accessorKey: "createdAt",
    header: "Created Date",
    enableColumnFilter: true,
    Cell: ({ row }) => <OneLiner text={userFriendlyDateTimestamp(row.original.createdAt)} />,
    filterVariant: "date",
    accessorFn: originalRow => (originalRow.createdAt ? new Date(originalRow.createdAt) : null),
  },
  {
    accessorKey: "updatedBy",
    header: "Updated By",
    enableSorting: true,
    Cell: ({ row }) => <OneLiner text={row.original.updatedBy?.name} />,
    enableColumnFilter: true,
  },
  {
    accessorKey: "updatedAt",
    header: "Updated Date",
    enableColumnFilter: true,
    Cell: ({ row }) => <OneLiner text={userFriendlyDateTimestamp(row.original.updatedAt)} />,
    size: 200,
    filterVariant: "date",
    accessorFn: originalRow => (originalRow.updatedAt ? new Date(originalRow.updatedAt) : null),
  },
  {
    accessorKey: "views",
    header: "Total Views",
    enableSorting: true,
    Cell: ({ row }) => <OneLiner text={row.original.views} />,
    enableColumnFilter: false,
  },
  {
    accessorKey: "likes",
    header: "Total Likes",
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ row }) => <OneLiner text={row.original.likes} />,
  },
  {
    accessorKey: "shares",
    header: "Total Shares",
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ row }) => <OneLiner text={row.original.shares} />,
  },
  {
    accessorKey: "hides",
    header: "Total Hides",
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ row }) => <OneLiner text={row.original.hides} />,
  },
];
export const useGetQuotesTableColumns = (params: Params) => {
  const { setHoveredRow, hoveredRow, activeTab, handleTableAction } = params;

  const [shouldCloseMenuSelection, setShouldCloseMenuSelection] = useState<boolean>(false);

  const handleAction = useCallback(
    (event: MouseEvent, action: ActionType, item: any) => {
      event.stopPropagation();
      setShouldCloseMenuSelection(true);
      handleTableAction(action, item);
    },
    [handleTableAction],
  );

  const canRemoveQuotes = useHasPermission(UserPermissions.REMOVE_QUOTES);
  const canApproveQuotes = useHasPermission(UserPermissions.APPROVE_PUBLISH_QUOTES);
  const canEditReadyAndUnPublishedQuotes = useHasPermission(UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_QUOTES);
  const canViewTranslations = useHasPermission(UserPermissions.VIEW_TRANSLATIONS);
  const canUnpublishQuotes = useHasPermission(UserPermissions.UNPUBLISH_QUOTES);

  const columns = useMemo(() => {
    const menuItems: MenuItemInterface[] = [
      {
        title: "Approve & Publish",
        action: "Publish",
        visible: activeTab === TabKeyEnum.ReadyForReview && canApproveQuotes,
      },
      {
        title: "Preview",
        action: "View",
        visible: true,
      },
      {
        title: "Edit Quote",
        action: "Edit",
        visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canEditReadyAndUnPublishedQuotes,
      },
      {
        title: "Edit Translation",
        action: "EditTranslation",
        visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canViewTranslations,
      },
      {
        title: "Unpublish",
        action: "UnPublish",
        visible: [TabKeyEnum.Published, TabKeyEnum.ReadyForReview].includes(activeTab) && canUnpublishQuotes,
      },
      {
        title: "Archive",
        action: "Archive",
        visible: [TabKeyEnum.Unpublished].includes(activeTab) && canUnpublishQuotes,
      },
      {
        title: "Unarchive",
        action: "Unarchive",
        visible: [TabKeyEnum.Archived].includes(activeTab) && canUnpublishQuotes,
      },
      {
        title: "Remove",
        action: "Remove",
        visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canRemoveQuotes,
        color: "red",
      },
    ];

    return [
      ...defaultColumns,
      {
        accessorKey: "action",
        id: "action",
        size: 50,
        header: "",
        enableResizing: false,
        muiTableHeadCellProps: () => ({
          sx: {
            visibility: "hidden",
          },
        }),
        Cell: ({ row }: any) => {
          if (hoveredRow !== row.index) return null;
          return menuItems.length > 0 && menuItems.some(item => item.visible) ? (
            <ActionCell
              shouldCloseMenuSelection={shouldCloseMenuSelection}
              setShouldCloseMenuSelection={setShouldCloseMenuSelection}
              hovered={hoveredRow === row.index}
              loading={false}
              activeTab={activeTab}
              onActionButtonClick={(event: MouseEvent<HTMLElement>) => {
                event?.stopPropagation?.();
                setHoveredRow(row.index);
              }}
            >
              {menuItems.map(
                item =>
                  item.visible && (
                    <MenuItem
                      key={item.title}
                      sx={item.color ? { color: item.color } : undefined}
                      onClick={event => handleAction(event, item.action, row.original)}
                    >
                      {item.title}
                    </MenuItem>
                  ),
              )}
            </ActionCell>
          ) : null;
        },
      },
    ];
  }, [
    activeTab,
    canApproveQuotes,
    canEditReadyAndUnPublishedQuotes,
    canViewTranslations,
    canUnpublishQuotes,
    canRemoveQuotes,
    hoveredRow,
    shouldCloseMenuSelection,
    setHoveredRow,
    handleAction,
  ]);

  return columns;
};
